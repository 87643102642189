import React, { useEffect, useState } from 'react';
import HomeSection from './section/HomeSection';
import ExperienceSection from './section/ExperienceSection';
import MobileNavBar from '../../components/MobileNavBar';
import AboutSection from './section/AboutSection';
import SkillsSection from './section/SkillsSection';
import ContactSection from './section/ContactSection';

const data = {
    "about": {
        "name": "Axel Colmant",
        "photo": "/assets/img/photo.png",
        "cv": "/assets/documents/CV_Axel-Colmant.pdf",
        "shortDescription": "**AI DEVELOPER**, Expertise in **ADVANCED ALGORITHMS** and **APPLIED RESEARCH**",
        "description": "My academic and professional journey has been marked by resilience and determination. Initially an average student, I faced skepticism from some professors during my early studies. Their doubts motivated me to push beyond expectations, leading me to graduate at the top of my class during my first master's degree.\nMy passion for **computer science** was ignited during my DUT GEII when I discovered programming. A simple tutorial on a neural network recognizing handwritten digits in Python sparked my fascination with **artificial intelligence** and shaped my career aspirations.\nAs I progressed through a computer science degree and a master's in Networks and IoT, I pursued personal projects in **computer vision**, deepening my expertise in AI despite administrative constraints. My studies, conducted entirely through work-study programs, provided diverse experiences in both small and large organizations, enriching my adaptability and technical skills.\nNow, as a second-year master's student in **Artificial Intelligence**, I have honed my knowledge of neural networks, computer vision, and machine learning techniques, including supervised and unsupervised learning. I am eager to further explore AI research, contribute to innovative projects, and leverage my determination to advance the field.",
        "softskills": [
            {
                "name": "Resilience",
                "icon": "AiOutlineThunderbolt"
            },
            {
                "name": "Determination",
                "icon": "FaRegFlag"
            },
            {
                "name": "Intellectual Curiosity",
                "icon": "FaBrain"
            },
            {
                "name": "Adaptability",
                "icon": "GiPathDistance"
            },
            {
                "name": "Attention to Detail",
                "icon": "AiOutlineEye"
            }
        ],
        "email": "contact@axelcolmant.com",
        "phone": "",
        "location": "Lyon, France",
        "socials": {
            "linkedin": "https://www.linkedin.com/in/axel-colmant/",
            "github": ""
        }
    },
    "experience": {
        "professional": [
            {
                "title": "Apprenti Ingénieur Génie Electrique et Informatique",
                "company": "REEL International",
                "companyLink": "https://www.reelinternational.com/",
                "companyLogo": "https://www.reelinternational.com/wp-content/uploads/2024/03/logo_reel_couleur.svg",
                "mapLink": "https://maps.app.goo.gl/ZQHpwp6wh5TJE8pW7",
                "address": "69 Rue de la Chaux",
                "city": "Saint-Cyr-au-Mont-d'Or",
                "zipCode": "69450",
                "country": "France",
                "startDate": "2019-09-01",
                "endDate": "2021-08-01",
                "description": "Prototyping of electrical components costing software for overhead cranes. \nDevelopment of a communication gateway (Ethway/Modbus)."
            },
            {
                "title": "Apprenti Développeur informatique",
                "company": "LE SAS ESCAPE GAME",
                "companyLink": "http://www.le-sas.com/",
                "companyLogo": "https://www.le-sas.com/public/assets/images/favicon.ico",
                "mapLink": "https://maps.app.goo.gl/pZmsnYJX6HUEy3Lr9",
                "address": "17 Av. Jean Moulin",
                "city": "Béziers",
                "zipCode": "34500",
                "country": "France",
                "startDate": "2021-11-02",
                "endDate": "2024-09-02",
                "description": "Creation of electronic and computer enigmas. \nConception and implementation of an IoT infrastructure to manage enigmas. \nDevelopment of software for creating and managing automated scenarios for escape game rooms."
            }
        ],
        "education": [
            {
                "degree": "DUT - Génie Électrique et Informatique Industrielle",
                "degreeDoc": "/assets/documents/formation/uga-dut-genie-electrique-et-informatique-industrielle.pdf",
                "institution": "Université Grenoble Alpes (IUT1)",
                "institutionShort": "UGA",
                "institutionLink": "https://iut1.univ-grenoble-alpes.fr/",
                "institutionLogo": "/assets/img/logo/institute/logo-uga-blanc.png",
                "address": "151 rue de la Papeterie",
                "city": "Saint-Martin-d'Hères",
                "zipCode": "38400",
                "country": "France",
                "startDate": "2019-09-01",
                "endDate": "2021-09-01"
            },
            {

                "degree": "BAC STI2D",
                "degreeDoc": "",
                "institution": "Lycée Notre Dame de Bel-Air",
                "institutionShort": "Lycée NDBA",
                "institutionLink": "https://www.csnd.fr/lgt-tarare/",
                "institutionLogo": "/assets/img/logo/institute/ndba-logo.png",
                "address": "5 av Belges",
                "city": "Tarare",
                "zipCode": "69170",
                "country": "France",
                "startDate": "2017-09-01",
                "endDate": "2019-09-01"
            },
            {
                "degree": "Licence - Informatique et spécialité Robotique de prototypage",
                "degreeDoc": "/assets/documents/formation/imerir-plaquette-licence-informatique-2024.pdf",
                "institutionLink": "https://www.imerir.com/",
                "institutionLogo": "/assets/img/logo/institute/imerir-logo.png",
                "institution": "Institut Méditerranéen d'Étude et Recherche en Informatique et Robotique",
                "institutionShort": "IMERIR",
                "address": "Avenue Pascot",
                "city": "Perpignan",
                "zipCode": "66000",
                "country": "France",
                "startDate": "2021-09-01",
                "endDate": "2022-09-01"
            },
            {
                "degree": "Master Réseau, Objets Connectés et Robotique de prototypage",
                "degreeDoc": "/assets/documents/formation/imerir-fiche-plaquette-a4-masterreseaux.pdf",
                "institution": "Institut Méditerranéen d'Étude et Recherche en Informatique et Robotique",
                "institutionShort": "IMERIR",
                "institutionLink": "https://www.imerir.com/",
                "institutionLogo": "/assets/img/logo/institute/imerir-logo.png",
                "address": "Avenue Pascot",
                "city": "Perpignan",
                "zipCode": "66000",
                "country": "France",
                "startDate": "2022-09-01",
                "endDate": "2024-09-01"
            },
            {
                "degree": "M2 - Intelligence Artificielle",
                "degreeDoc": "/assets/documents/formation/ucb-master-informatique.pdf",
                "institution": "Université Claude Bernard Lyon 1",
                "institutionShort": "UCBL",
                "institutionLink": "https://www.univ-lyon1.fr/",
                "institutionLogo": "/assets/img/logo/institute/ucbl-logo.png",
                "address": "Bâtiment Nautibus - 23-25 Avenue Pierre de Coubertin",
                "city": "Villeurbanne",
                "zipCode": "69100",
                "country": "France",
                "startDate": "2024-09-02",
                "endDate": "2025-08-01"
            }
        ],
        "projects": [
            {
                "title": "Projet de fin d'études - Réalité Augmentée",
                "image": "https://placehold.co/500x500",
                "startDate": "2024-09-01",
                "endDate": "2025-08-01",
                "description": "Développement d'une application de réalité augmentée pour la visualisation de modèles 3D en temps réel.",
                "link": ""
            },{
                "title": "Projet de fin d'études - Réalité Augmentée",
                "image": "https://placehold.co/500x500",
                "startDate": "2024-09-01",
                "endDate": "2025-08-01",
                "description": "Développement d'une application de réalité augmentée pour la visualisation de modèles 3D en temps réel.",
                "link": ""
            },{
                "title": "Projet de fin d'études - Réalité Augmentée",
                "image": "https://placehold.co/500x500",
                "startDate": "2024-09-01",
                "endDate": "2025-08-01",
                "description": "Développement d'une application de réalité augmentée pour la visualisation de modèles 3D en temps réel.",
                "link": ""
            },{
                "title": "Projet de fin d'études - Réalité Augmentée",
                "image": "https://placehold.co/500x500",
                "startDate": "2024-09-01",
                "endDate": "2025-08-01",
                "description": "Développement d'une application de réalité augmentée pour la visualisation de modèles 3D en temps réel.",
                "link": ""
            }
        ]
    },
    "skills": {
        "Programming Languages": [
            { "name": "Python", "rating": 5, "icon": "FaPython", "keywords": ["scripting", "data science", "machine learning", "web development", "automation", "scientific computing", "AI", "backend"] },
            { "name": "Java", "rating": 4, "icon": "FaJava", "keywords": ["OOP", "backend development", "enterprise applications", "Android development", "multithreading", "high-performance computing", "data structures"] },
            { "name": "C++", "rating": 4, "icon": "SiCplusplus", "keywords": ["system programming", "game development", "high-performance applications", "embedded systems", "memory management", "data structures", "algorithms"] },
            { "name": "C", "rating": 3, "icon": "SiC", "keywords": ["low-level programming", "embedded systems", "system kernels", "performance-critical applications", "hardware interaction"] },
            { "name": "JavaScript", "rating": 5, "icon": "SiJavascript", "keywords": ["frontend", "web development", "dynamic websites", "event-driven programming", "single-page applications", "DOM manipulation"] },
            { "name": "HTML", "rating": 5, "icon": "FaHtml5", "keywords": ["markup language", "web pages", "semantic tags", "frontend", "SEO optimization"] },
            { "name": "CSS", "rating": 5, "icon": "FaCss3Alt", "keywords": ["styling", "design", "responsive web", "animations", "frontend frameworks"] }
        ],
        "AI Framework": [
            { "name": "TensorFlow", "rating": 3, "icon": "SiTensorflow", "keywords": ["python", "deep-learning", "neural-networks", "model-training", "high-level APIs", "AI research", "data processing"] },
            { "name": "Keras", "rating": 2, "icon": "SiKeras", "keywords": ["python", "neural networks", "API abstraction", "model development", "deep learning pipelines"] },
            { "name": "PyTorch", "rating": 4, "icon": "SiPytorch", "keywords": ["python", "deep-learning", "research", "flexible model design", "AI experimentation"] },
            { "name": "OpenCV", "rating": 3, "icon": "SiOpencv", "keywords": ["python", "computer-vision", "image-processing", "real-time video analysis", "object detection"] },
            { "name": "Scikit-learn", "rating": 3, "icon": "SiScikitlearn", "keywords": ["python", "machine learning", "data preprocessing", "statistical modeling", "classification", "regression"] }
        ],
        "Frontend": [
            { "name": "Qt", "rating": 4, "icon": "SiQt", "keywords": ["C++", "Python bindings", "GUI development", "cross-platform", "desktop applications"] },
            { "name": "HTML", "rating": 4, "icon": "FaHtml5", "keywords": ["markup language", "semantic elements", "frontend", "accessibility"] },
            { "name": "CSS", "rating": 4, "icon": "FaCss3Alt", "keywords": ["web design", "grid systems", "flexbox", "animations", "themes"] },
            { "name": "JavaScript", "rating": 4, "icon": "SiJavascript", "keywords": ["frontend scripting", "interactive web apps", "ES6+", "API integration"] },
            { "name": "React", "rating": 3, "icon": "FaReact", "keywords": ["component-based architecture", "frontend libraries", "hooks", "state management"] },
            { "name": "Next.js", "rating": 2, "icon": "SiNextdotjs", "keywords": ["server-side rendering", "static site generation", "React framework", "API routes"] },
            { "name": "Tailwind", "rating": 2, "icon": "SiTailwindcss", "keywords": ["utility-first CSS", "responsive design", "frontend customization"] }
        ],
        "Backend": [
            { "name": "Node.js", "rating": 4, "icon": "FaNodeJs", "keywords": ["JavaScript runtime", "event-driven architecture", "backend development", "scalable applications"] },
            { "name": "Express", "rating": 4, "icon": "SiExpress", "keywords": ["lightweight web framework", "API design", "backend routing"] },
            { "name": "MongoDB", "rating": 2, "icon": "SiMongodb", "keywords": ["NoSQL database", "document-oriented", "scalability", "flexible schemas"] },
            { "name": "MySQL", "rating": 3, "icon": "SiMysql", "keywords": ["relational database", "structured queries", "data integrity", "backend integration"] }
        ],
        "Tools": [
            { "name": "Git", "rating": 5, "icon": "FaGitAlt", "keywords": ["version control", "branching", "collaboration", "repository management"] },
            { "name": "Docker", "rating": 4, "icon": "FaDocker", "keywords": ["containerization", "application deployment", "microservices", "devops pipelines"] },
            { "name": "Jupyter", "rating": 5, "icon": "SiJupyter", "keywords": ["interactive notebooks", "data visualization", "exploratory analysis"] }
        ],
        "IDEs": [
            { "name": "VS Code", "rating": 5, "icon": "VscVscodeInsiders", "keywords": ["python", "code editor", "extensions", "debugging", "multi-language support"] },
            { "name": "PyCharm", "rating": 3, "icon": "SiPycharm", "keywords": ["Python IDE", "intelligent code completion", "debugging"] },
            { "name": "IntelliJ", "rating": 2, "icon": "SiIntellijidea", "keywords": ["Java IDE", "enterprise development", "integrated tools"] },
            { "name": "Android Studio", "rating": 2, "icon": "SiAndroidstudio", "keywords": ["mobile development", "Android-specific tools", "emulator integration"] }
        ]
    }



}

function HomePage() {
    const [height, setHeight] = useState(0);
    const [currentHash, setCurrentHash] = useState('#home');

    function getNearestSection() {
        const listSections = document.querySelectorAll('.section');
        let nearestCenter = Infinity;
        let nearestSection = null;
        listSections.forEach((section) => {
            const rect = section.getBoundingClientRect();
            const center = rect.top + rect.height / 2 - window.innerHeight / 2;
            if (Math.abs(center) < nearestCenter) {
                nearestCenter = Math.abs(center);
                nearestSection = section;
            }
        });
        return nearestSection;
    }


    const scrollToSection = (sectionId, behavior = 'smooth') => {
        const section = document.querySelector(sectionId);
        if (section) {
            section.scrollIntoView({ behavior: behavior });
        }
    };

    const navigateToSection = (sectionId) => {
        scrollToSection(sectionId);
    };

    useEffect(() => {
        const main = document.querySelector('main');
        const header = document.querySelector('header');
        const footer = document.querySelector('footer');

        const handleResize = () => {
            setHeight(window.innerHeight - header.clientHeight - footer.clientHeight);
        };
        handleResize();

        const onScroll = () => {
            const nearestSection = getNearestSection();
            if (nearestSection) {
                setCurrentHash(`#${nearestSection.id}`);
            }
            
        };
        // set scroll to 0 to avoid scroll to section when the page is loaded
        main.scrollTo(0, 0);
        if (window.location.hash) {
            setTimeout(() => {
                scrollToSection(window.location.hash, 'auto');
            }, 300);    
        }
        
        window.addEventListener('resize', handleResize);
        main.addEventListener('resize', handleResize);
        main.addEventListener('scroll', onScroll);
        main.style.scrollSnapType = 'y proximity';
        const sections = document.querySelectorAll('.section');
        sections.forEach((section) => {
            section.style.scrollSnapAlign = 'start';
        });
        return () => {
            main.removeEventListener('resize', handleResize);
            window.removeEventListener('resize', handleResize);
            main.removeEventListener('scroll', onScroll);
        }
    }, []);




    return (
        <div
            className="relative z-10"
        >
            <div className='w-full flex-grow flex flex-col p-2 sm:p-4 items-center gap-20'>
                <HomeSection id="home" data={data.about} style={{ minHeight: `${height}px` }} />
                <AboutSection id="about" data={data.about} style={{ minHeight: `${height}px` }} />
                <SkillsSection id="skills" data={data.skills} style={{ minHeight: `${height}px` }} />
                <ExperienceSection id="experience" data={data.experience} style={{ minHeight: `${height}px` }} />
                <ContactSection id="contact" data={data.about} style={{ minHeight: `${height}px` }} />
            </div>
            <MobileNavBar currentHash={currentHash} setCurrentHash={navigateToSection} />
        </div>
    );
}

export default HomePage;
