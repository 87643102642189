import { FaGithub, FaLinkedinIn } from 'react-icons/fa'

const socials = [
    {
        icon: <FaGithub />,
        path: 'https://github.com/axel-colm'
    },
    {
        icon: <FaLinkedinIn />,
        path: 'https://www.linkedin.com/in/axel-colmant-9853b4201/'
    }
]

const Social = ({ containerStyles, iconStyles }) => {

    return (
        <div className={containerStyles}>
            {socials.map((item, index) => {
                return (
                    <a 
                        key={index} 
                        href={item.path} 
                        target="_blank" 
                        rel="noopener noreferrer" 
                        className={iconStyles} >
                            {item.icon}
                    </a>
                )
            })}            
        </div>
    );
}

export default Social;