import React from 'react';
import ToggleDark from './ToggleDark';

function Header({ ...props }) {
    const [hover, setHover] = React.useState(false);

    return (
        <header
            {...props}
            className="flex items-center justify-left p-4 gap-2"
        >
            <a
                href="https://axelcolmant.com"
                className="flex text-text/70 dark:text-text-dark/70 font-bold text-xl md:text-3xl gap-2"
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
            >
                <span className="text-accent dark:text-accent-dark font-extrabold dark:font-bold">{'>'}</span>
                <div>
                    <span className='text-text dark:text-text-dark absolute transition-transform duration-500 transform rotate-x-0' style={{ transform: hover ? 'rotateX(0deg)' : 'rotateX(-180deg)', backfaceVisibility: 'hidden' }} translate="no">
                        Axel Colmant
                    </span>

                    <span className='absolute transition-transform duration-500 transform rotate-x-0' style={{ transform: hover ? 'rotateX(180deg)' : 'rotateX(0deg)', backfaceVisibility: 'hidden' }} translate="no">
                        Portfolio
                    </span>
                </div>
            </a>

            <div className="flex-grow" >

            </div>
            {/* <GoogleTranslate /> */}
            <ToggleDark />
        </header>
    );
}

export default Header;
