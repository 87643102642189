import React, { useState } from 'react';
import Section from './Section';
import { HiOutlineMail, HiOutlinePhone } from 'react-icons/hi';

function ContactSection({ data, ...props }) {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");
    const [sent, setSent] = useState(false);
    const [errors, setAllErrors] = useState({
        name: "",
        email: "",
        message: ""
    });


    function submitHandler(e) {
        e.preventDefault();
        const form = document.getElementById('contact-form');
        const name = form.name.value;
        const email = form.email.value;
        const message = form.message.value;
        const errors = {};

        if (name === "") {
            errors.name = "Name is required";
        }

        if (email === "") {
            errors.email = "Email is required";
        } else if (!validateEmail(email)) {
            errors.email = "Email is invalid";
        }

        if (message === "") {
            errors.message = "Message is required";
        }

        if (Object.keys(errors).length > 0) {
            setAllErrors(errors);
            return;
        }

        // Send email
        send(name, email, message);
        
    }

    function validateEmail(email) {
        const re = /\S+@\S+\.\S+/;
        return re.test(email);
    }

    function resetForm() {
        setName("");
        setEmail("");
        setMessage("");
        setSent(false);
    }

    function send(name, email, message) {
        // Send email
        console.log(name, email, message);
        setSent(true);
    }




    return (
        <Section title='Contact me' {...props}>
            <div className="flex flex-col lg:flex-row items-start gap-10 order-none lg:order-2">
                <div className="flex flex-col gap-4 justify-center items-center ">
                    <div className="flex flex-col gap-4 p-[40px] w-full rounded-3xl">
                        <div className="flex items-center gap-4 text-xl">
                            {/* Icon */}
                            <HiOutlineMail className="text-accent text-[4vh]" />
                            <a href={`mailto:${data?.email}`} className="hover:underline hover:text-accent">{data?.email || '-'}</a>
                        </div>
                        <div className="flex items-center gap-4 text-xl">
                            {/* Icon */}
                            <HiOutlinePhone className="text-accent text-[4vh]" />
                            <a href={`tel:${data?.phone }`} className="hover:underline hover:text-accent">{data?.phone || '-'}</a>
                        </div>
                    </div>
                </div>

                <div className="flex flex-col gap-4 w-full">
                    {
                        sent ? (
                            <div className="bg-white dark:bg-[#0A1623] text-text dark:text-text-dark flex flex-col gap-4 p-[40px] rounded-3xl">
                                <h2 className="text-2xl font-bold">Thank you for your message!</h2>
                                <p className="text-lg">I will get back to you as soon as possible.</p>
                                <button className="max-w-[200px] p-2 rounded-md bg-accent dark:bg-accent-dark text-background dark:text-background-dark hover:bg-accent-dark hover:text-background dark:hover:text-background-dark transition-colors duration-300" onClick={() => resetForm()}>Send another message</button>
                            </div>
                        ): (
                            <form className="bg-white dark:bg-[#0A1623] text-text dark:text-text-dark flex flex-col gap-[20px] p-[40px] rounded-3xl" onSubmit={submitHandler} id="contact-form">
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                            <div className="flex flex-col relative">
                                <label htmlFor="name" className="absolute -top-3 left-2 px-1 text-sm bg-white dark:bg-[#0A1623]">Name</label>
                                <input type="text" id="name" name="name" placeholder="Your name" onChange={(e) => setName(e.target.value)} className="input p-2 border bg-transparent border-gray-300 rounded-md focus:ring-accent" />
                                <p className="text-red-500 hidden" id="firstname-error">{errors.name}</p>
                            </div>
                            <div className="flex flex-col relative">
                                <label htmlFor="email" className="absolute -top-3 left-2 px-1 text-sm bg-white dark:bg-[#0A1623]">Email</label>
                                <input type="email" id="email" name="email" placeholder="Your email" onChange={(e) => setEmail(e.target.value)} className="input p-2 border bg-transparent border-gray-300 rounded-md focus:ring-accent" />
                                <p className="text-red-500 hidden" id="email-error">{errors.email}</p>
                            </div>
                        </div>
                        {/* Textarea */}
                        <div className="flex flex-col relative">
                            <label htmlFor="message" className="absolute -top-3 left-2 px-1 text-sm bg-white dark:bg-[#0A1623]">Message</label>
                            <textarea
                                id="message"
                                name="message"
                                placeholder="Type your message here"
                                onChange={(e) => setMessage(e.target.value)}
                                className="input p-2 border bg-transparent border-gray-300 rounded-md h-[200px] resize-none overflow-auto focus:ring-accent"
                            />
                            <p className="text-red-500 hidden" id="message-error">{errors.message}</p>
                        </div>

                        <button
                            type="submit"
                            className="max-w-[200px] p-2 rounded-md bg-accent dark:bg-accent-dark text-background dark:text-background-dark hover:bg-accent-dark hover:text-background dark:hover:text-background-dark transition-colors duration-300 disabled:opacity-50 disabled:hover:bg-accent disabled:hover:text-background"
                            disabled={name === "" || email === "" || message === ""}
                        >
                            Send
                        </button>
                    </form>
                        )
                    }
                </div>
            </div>
        </Section>
    );
};

export default ContactSection;