import React from 'react';
import { HiOutlineHome, HiOutlineUser, HiOutlineBriefcase, HiOutlineBookmark } from "react-icons/hi2";
import { IoChatbubbleEllipsesOutline } from "react-icons/io5";
import { Link } from 'react-router-dom';

function MobileNavBar({currentHash, setCurrentHash, ...props }) {
    const routes = [
        {
            name: 'Home',
            link: '#home',
            icon: <HiOutlineHome />,
        },
        {
            name: 'About',
            link: '#about',
            icon: <HiOutlineUser />,
        },
        {
            name: 'Skills',
            link: '#skills',
            icon: <HiOutlineBookmark />,
        },
        {
            name: 'Experience',
            link: '#experience',
            icon: <HiOutlineBriefcase />,
        },
        {
            name: 'Contact',
            link: '#contact',
            icon: <IoChatbubbleEllipsesOutline />,
        }
    ];

    

    return (
        <div className="fixed bottom-0 left-1/2 w-[80vw] sm:w-1/2 lg:w-1/3 transform -translate-x-1/2 z-100 rounded-[7vh] bg-text-dark dark:bg-background-dark shadow-lg shadow-t z-50">
            <nav className="p-0 px-[0vh] rounded-[7vh] bg-black/20">
                <ul className="flex justify-between items-center h-full">
                    {routes.map((route, index) => (
                        <li key={index} className="flex flex-col items-center justify-center h-full">
                            <Link
                                to={route.link}
                                className={`group flex flex-col items-center justify-center h-full bg-transparent rounded-full ${currentHash === route.link ? "text-text dark:text-text-dark -translate-y-[1vh]" : "text-text/50 dark:text-text-dark/50"}`}
                                onClick={() => setCurrentHash(route.link)}
                            >
                                <div
                                    className={`flex flex-col items-center justify-center h-[7vh] w-[7vh] text-[3.5vh] rounded-full bg-transparent transition-transform duration-300 `}
                                >
                                    {route.icon}
                                    {(currentHash === route.link) && <div className="h-1 w-1/3 bg-accent rounded-full mt-1"></div>}
                                </div>
                                <span className="hidden">{route.name}</span>
                            </Link>
                        </li>
                    ))}
                </ul>
            </nav>
        </div>
    );
};

export default MobileNavBar;
