import Section from './Section';
import React, { useEffect, useState } from 'react';
import { getIcon } from '@/components/Icon';
import { FaChevronLeft as LeftIcon, FaChevronRight as RightIcon, FaSearch } from 'react-icons/fa';
import { IoCloseOutline } from 'react-icons/io5';


function SkillItem({ skill, ...props }) {

    props.className = props.className + ' flex flex-col items-center justify-center gap-4 p-4 rounded-3xl text-background-dark dark:text-text-dark';
    props.style = { minHeight: '150px', maxHeight: '200px' };
    const Icon = getIcon(skill.icon);
    return (
        <div {...props}>
            <Icon className='w-1/2 h-full 50 hover:scale-110' />
            <div className='flex flex-col w-full items-center gap-4 text-text dark:text-text-dark'>
                <span className='h-1/6 text-center font-bold text-sm sm:text-lg flex items-center select-none'>
                    {skill.name}
                </span>
                <div className='flex flex-row gap-1'>
                    {[...Array(5)].map((_, i) => {
                        const Icon = getIcon('FaStar');
                        return (
                            <Icon key={i} className={`h-full text-sm ${i < skill.rating ? 'text-accent' : 'text-text/30 dark:text-text-dark/30'}`} />

                        );
                    })}

                </div>
            </div>
        </div>
    );
}

function SkillsSection({ data, ...props }) {
    const [activeTab, setActiveTab] = useState('Frontend');
    const [searchTerm, setSearchTerm] = useState([]);
    const [serachResults, setSearchResults] = useState({});

    const tableTags = Object.keys(data);
    for (const key in data) {
        data[key].sort((a, b) => b.rating - a.rating);
    }

    function onSubmitSearch(e) {
        e.preventDefault();
        const terms = e.target[0].value.toLowerCase().split(' ').filter(term => term.length > 0);
        // add terms if not already in the list
        setSearchTerm([...new Set([...searchTerm, ...terms])]);
        e.target[0].value = '';
    }


    useEffect(() => {
        function searchSkill(key) {
            const results = {};
            for (const tag in data) {
                results[tag] = data[tag].filter(skill =>
                    skill.name.toLowerCase().includes(key)
                    ||
                    skill.keywords.some(keyword => keyword.toLowerCase().includes(key))

                );
            }
            // remove empty tags
            for (const tag in results) {
                if (results[tag].length === 0) {
                    delete results[tag];
                }
            }
            return results;
        }

        const results = {};
        searchTerm.forEach(term => {
            const searchResults = searchSkill(term);
            for (const tag in searchResults) {
                if (tag in results) {
                    results[tag] = results[tag].concat(searchResults[tag]);
                } else {
                    results[tag] = searchResults[tag];
                }
            }

        });
        setSearchResults(results);
    }, [searchTerm, data]);

    const header = (
        <div className='flex flex-col w-full xl:w-2/3 gap-2 justify-center items-center'>
            <div className='relative w-full'>
                <form className='w-full flex flex-row gap-2 justify-center items-center' onSubmit={onSubmitSearch}>
                    <input type='text'
                        className='w-full h-[40px] text-lg p-2 rounded-full px-4 bg-black/10 text-text dark:bg-text-dark/30 dark:text-text-dark shadow-2xl border-0 focus:ring-2 focus:ring-accent focus:outline-none'
                        placeholder='Search for a skill'
                    />
                    <button
                        className='absolute right-[4px] top-1/2 transform -translate-y-1/2 p-2 rounded-full bg-accent text-white hover:scale-110'
                        type='submit'
                    >
                        <FaSearch className='w-[16px] h-full' />
                    </button>
                </form>
            </div>

            <div className='flex flex-wrap gap-2 w-full '>
                {
                    searchTerm?.map(term => {
                        return (
                            <div
                                className='flex flex-row text-sm gap-2 p-1 pl-4 rounded-full bg-accent text-white justify-between items-center cursor-pointer select-none'
                                key={term}
                                onMouseDown={(e) => {
                                    if (e.button === 1) {
                                        setSearchTerm(searchTerm.filter(t => t !== term));
                                    }
                                }}
                            >
                                <span className='w-full'>{term}</span>
                                <button
                                    className='p-1 rounded-full text-text dark:text-text-dark bg-accent hover:bg-accent/50 hover:scale-130'
                                    onClick={() => {
                                        setSearchTerm(searchTerm.filter(t => t !== term));
                                    }}
                                >
                                    <IoCloseOutline className='w-[16px] h-full font-bold' />
                                </button>
                            </div>
                        )
                    })
                }
            </div>

            {
                searchTerm?.length === 0 && (
                    <div className="flex flex-row w-full rounded-2xl justify-between items-center">
                                <button
                                    className="left-0 hover:text-accent rounded-full"
                                    onClick={() => {
                                        document.querySelector('.scrollable-container').scrollBy({ left: -200, behavior: 'smooth' });
                                    }}
                                >
                                    <LeftIcon className='w-[20px] h-full' />
                                </button>
                                <div className="scrollable-container flex flex-row gap-2 overflow-x-auto w-full rounded-2xl"
                                    style={{ scrollbarWidth: 'none', scrollbarHeight: 'none' }}
                                    onMouseDown={(e) => {
                                        const element = e.currentTarget;
                                        element.isDragging = true;
                                        element.startX = e.pageX - element.offsetLeft; // Position initiale de la souris
                                        element.scrollLeftStart = element.scrollLeft; // Position initiale du scroll
                                    }}
                                    onMouseMove={(e) => {
                                        const element = e.currentTarget;
                                        if (!element.isDragging) return; // Ne rien faire si on ne "grab" pas
                                        e.preventDefault(); // Bloque la sélection de texte
                                        const x = e.pageX - element.offsetLeft; // Position actuelle de la souris
                                        const walk = (x - element.startX) * 1.5; // Multiplieur pour ajuster la vitesse
                                        element.scrollLeft = element.scrollLeftStart - walk; // Déplacement du scroll
                                    }}
                                    onMouseUp={(e) => {
                                        e.currentTarget.isDragging = false; // Arrête le "grab"
                                    }}
                                    onMouseLeave={(e) => {
                                        e.currentTarget.isDragging = false; // Arrête le "grab" si la souris quitte la div
                                    }}
                                >

                                    {tableTags.map(tag => (
                                        <div key={tag} className=" bg-background dark:bg-background-dark  xl:w-[10vw] font-bold text-sm lg:text-xl rounded-xl justify-center items-center">
                                            <button
                                                className={`px-8 text-white ${activeTab === tag ? 'bg-accent' : 'bg-black/30 hover:bg-black/50'} p-2 w-full h-full rounded-xl flex items-center justify-center`}
                                                onClick={(e) => {
                                                    setActiveTab(tag);
                                                    e.currentTarget.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' });
                                                }}
                                            >
                                                {tag}
                                            </button>
                                        </div>
                                    ))}
                                </div>
                                <button
                                    className="left-0 hover:text-accent p-2 rounded-full"
                                    style={{ display: tableTags.length > 3 ? 'block' : 'none' }}
                                    onClick={() => {
                                        document.querySelector('.scrollable-container').scrollBy({ left: 200, behavior: 'smooth' });
                                    }}
                                >
                                    <RightIcon className='w-[20px] h-full' />
                                </button>
                            </div>
                )
            }
        </div>
    )

    return (
        <Section title='My Skills' head={header} {...props}>
            <div className='flex flex-col gap-4 justify-start items-center'>
                {
                    searchTerm?.length > 0 ? (
                        <>
                            <div className='flex flex-col gap-4 w-full'>
                                {
                                    serachResults?.length > 0 ? (
                                        tableTags.map(tag => {
                                            return (
                                                <div className="w-full gap-4" key={tag} style={{ display: tag in serachResults ? 'block' : 'none' }}>
                                                    <div className='w-full flex flex-row gap-2 items-center justify-start text-xl'>
                                                        <div className='min-w-[4px] h-8 bg-accent rounded-full' />
                                                        <span className='font-bold '>{tag} :</span>
                                                    </div>
                                                    <div className='w-full grid grid-cols-2 xl:grid-cols-3 py-4 gap-4'>
                                                        {
                                                            serachResults[tag]?.map((skill, index) => {
                                                                const Icon = getIcon(skill.icon);
                                                                if (!Icon) return null;
                                                                return (
                                                                    <SkillItem key={index} skill={skill} />
                                                                );
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            )
                                        })
                                    ) : (
                                        <div className='flex flex-col items-center justify-center gap-4 p-4  rounded-3xl text-text/50 dark:text-text-dark/50 hover:text-text hover:dark:text-text-dark'>
                                            <span className='h-1/6 text-center font-bold text-[1.5vw] flex items-center select-none'>
                                                No search results
                                            </span>
                                        </div>
                                    )

                                }



                            </div>
                        </>
                    ) : (
                        <>
                            
                            <div className='grid grid-cols-3 md:grid-cols-4 w-full gap-2'>
                                {
                                    data[activeTab].map((skill, index) => (
                                        <SkillItem key={index} skill={skill} />
                                    ))
                                }
                            </div>
                        </>
                    )
                }
            </div>
        </Section >
    );
};

export default SkillsSection;