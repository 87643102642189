import React from 'react';
function Footer({...props}) {
    const properties = {
        ...props,
        className: 'flex items-center justify-between p-4' + (props.className ? ` ${props.className}` : ''),
    };
    return (
        <footer {...properties}>
            <p>&copy; {new Date().getFullYear()} Axel Colmant</p>
        </footer>
    );
}

export default Footer;