import { FiDownload } from "react-icons/fi"
import Social from "./personal-info/Social";
import Photo from "./personal-info/Photo";
import ReactMarkdown from "react-markdown";


export default function HomeSection({ data, ...props }) {

    function DownloadCV() {
        const path = data?.cv;
        window.open(path, "_blank");
    }
    props.className = "section h-full w-full flex flex-col xl:flex-row items-center justify-center gap-4 xl:gap-10" + (props.className ? " " + props.className : "");
    return (
        <div {...props}>
            <div className="flex flex-col w-full xl:w-2/3 h-2/3 xl:h-full justify-center text-center xl:text-left text-text dark:text-text-dark order-2 gap-4 xl:order-none">
                <span className="leading-[1.1] font-semibold" style={{ fontSize: "max(4.5vw, 5vh)" }}>
                    Hi, I&apos;m<br />
                    <span className="text-accent uppercase">{data?.name}</span>
                </span>
                <ReactMarkdown className="text-black dark:text-white text-[2vh] mb-4">
                    {data?.shortDescription}
                </ReactMarkdown>

                {/* button and socials */}
                <div className="flex flex-col xl:flex-row items-center gap-4 xl:gap-8">
                    <button
                        className="uppercase flex items-center gap-4 w-auto min-w-[100px] h-[5vh] xl:h-[7vh] rounded-full 
                        border border-accent dark:border-accent-dark 
                        text-background dark:text-accent-dark hover:text-accent hover:dark:text-background-dark 
                        bg-accent dark:bg-background-dark hover:bg-background hover:dark:bg-accent-dark
                        transition-all duration-500 px-4 xl:px-6 py-2 xl:py-3 text-[1.8vh] xl:text-[2.3vh] font-semibold"
                        onClick={DownloadCV}
                    >
                        <span className="text-[1.8vh]">Download CV</span>
                        <FiDownload className="text-[2.3vh]" />
                    </button>


                    <div className="xl:mb-0">
                        <Social containerStyles="flex gap-8" iconStyles="h-[5vh] w-[5vh] xl:w-[7vh] xl:h-[7vh] rounded-full flex justify-center items-center 
                        border border-accent dark:border-accent-dark 
                        text-background dark:text-accent-dark hover:text-accent hover:dark:text-background-dark 
                        bg-accent dark:bg-background-dark hover:bg-background hover:dark:bg-accent-dark
                        hover:transition-all duration-500 text-[2.5vh] xl:text-[3.5vh]" />
                    </div>
                </div>

            </div>

            { /* Photo */}
            <Photo path={data?.photo} className="w-1/2 lg:w-1/3 h-1/2 xl:h-full order-1 xl:order-none" />
        </div>
    )
}