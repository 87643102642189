import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from '@/pages/home/HomePage';
import Layout from './components/Layout';

function App() {

  return (
    <Layout>
      <Router>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="*" element={404} />
        </Routes>
      </Router>
    </Layout>
  );
}

export default App;
