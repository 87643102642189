import React from 'react';
import { useTheme } from '../ThemeContext';
import { MdDarkMode, MdLightMode } from 'react-icons/md';

const ToggleDark = ({ ...props }) => {
    const { darkMode, toggleDarkMode } = useTheme();

    return (
        <div {...props} onClick={toggleDarkMode} className="cursor-pointer">
            {darkMode ? <MdLightMode size={24} /> : <MdDarkMode size={24} />}
        </div>
    );
};

export default ToggleDark;