import React from 'react';

function Section({ title, head, children, ...props }) {
    // Ajout d'une classe supplémentaire pour personnaliser
    const className = `${props.className || ''} section flex flex-col gap-8 w-full lg:mb-[10vw]`;

    return (
        <div {...props} className={className}>
            <div className="sticky top-0 z-50 text-[4vh] lg:text-[4vw] w-full text-center flex flex-col items-center justify-center dark:bg-background-dark bg-background ">
                <span className="font-bold text-shadow-xl pointer-events-none text-text dark:text-text-dark">
                    {title} <span className="text-accent">.</span>
                </span>
                {head}
            </div>

            <div>
                {children}
            </div>
        </div>
    );
}

export default Section;
