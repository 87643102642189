import React from 'react';
import Section from './Section';
import { HiOutlineArrowRight } from 'react-icons/hi2';

function formatDate(date) {
    const options = { year: 'numeric', month: 'short' };
    return new Date(date).toLocaleDateString(undefined, options);
}

function calculateDuration(startDate, endDate) {
    const start = new Date(startDate);
    const end = endDate === 'Present' ? new Date() : new Date(endDate);
    const duration = new Date(end - start);
    const years = duration.getUTCFullYear() - 1970;
    const months = duration.getUTCMonth();
    return `${years > 0 ? years + ' year' + (years > 1 ? 's' : '') + (months > 0 ? ' ' : '') : ''}${months > 0 ? months + ' month' + (months > 1 ? 's' : '') : ''}`;
}



function Item({ children, ...props }) {
    props.className = 'relative flex flex-col justify-between items-start w-full h-full bg-gradient-to-tr from-black/5 to-white dark:from-black/30 dark:to-black/10 rounded-3xl p-8 gap-4 shadow-sm overflow-hidden';
    return (
        <div className='relative m-2 bg-transparent'>
            <div {...props}>
                {children}
            </div>
            <div className='absolute inset-0 rounded-3xl shadow-lg z-0'></div>
        </div>
    );
}

function ProfessionalItem({ data }) {
    const endDate = data.endDate === 'none' || new Date(data.endDate) > new Date() ? 'Present' : data.endDate;
    const formattedStartDate = formatDate(data.startDate);
    const formattedEndDate = endDate === 'Present' ? 'Present' : formatDate(endDate);
    const duration = calculateDuration(data.startDate, endDate);

    return (
        <Item>
            <div>
                <h1 className='text-lg sm:text-xl font-bold'>{data.title}</h1>
                <h2 className='text-sm sm:text-lg uppercase'>{data.company}</h2>
                <p className='text-xs sm:text-sm opacity-50'>{formattedStartDate} - {formattedEndDate} ({duration})</p>
            </div>
            <button className='flex flex-row items-center text-accent dark:text-accent-dark font-bold dark:font-normal text-sm sm:text-lg group'>
                Learn more
                <HiOutlineArrowRight className='inline ml-2 transition-transform duration-300 group-hover:translate-x-1' />
            </button>
        </Item>
    );
}



function EducationItem({ data }) {
    const endDate = data.endDate === 'none' || new Date(data.endDate) > new Date() ? 'Present' : data.endDate;
    const formattedStartDate = formatDate(data.startDate);
    const formattedEndDate = endDate === 'Present' ? 'Present' : formatDate(endDate);

    return (
        <Item>
            <div>
                <h1 className='text-lg sm:text-xl font-bold'>{data.degree}</h1>
                <h2 className='text-sm sm:text-lg'><span className='uppercase'>{data.institutionShort}</span> - {data.city}</h2>
                <p className='text-xs sm:text-sm opacity-50'>{formattedStartDate} - {formattedEndDate}</p>
            </div>
            <button className='flex flex-row items-center text-accent dark:text-accent-dark font-bold dark:font-normal text-sm sm:text-lg group'>
                Learn more
                <HiOutlineArrowRight className='inline ml-2 transition-transform duration-300 group-hover:translate-x-1' />
            </button>
        </Item>
    );
}

function ProjectItem({ data }) {

    return (
        <Item>
            <div className='flex flex-col w-full h-full items-center gap-4'>
                <img src={data.image} alt={data.title} className='w-full object-contain rounded-xl' />
                <h1 className='text-lg sm:text-xl font-bold'>{data.title}</h1>
            </div>
            <button className='flex flex-row items-center text-accent-dark font-bold dark:font-normal text-sm sm:text-lg group'>
                Learn more
                <HiOutlineArrowRight className='inline ml-2 transition-transform duration-300 group-hover:translate-x-1' />
            </button>
        </Item>
    );
}

function ExperienceSection({ data, ...props }) {
    const [activeTab, setActiveTab] = React.useState("professional");
    const keys = ["professional", "education", "projects"];

    data.professional.sort((a, b) => new Date(b.startDate) - new Date(a.startDate));
    data.education.sort((a, b) => new Date(b.startDate) - new Date(a.startDate));

    const header = (
        <div className='flex flex-wrap justify-center items-center gap-2 pt-2 w-full' id="experience-tabs">
            {keys.map((key, i) => (
                <button
                    key={i}
                    className={`flex flex-1 items-center justify-center text-white ${activeTab === key ? 'bg-accent' : 'bg-background dark:bg-background-dark'} rounded-xl h-full flex-grow`}
                    onClick={(e) => {
                        setActiveTab(key)
                        const tabContainer = document.getElementById('experience-tabs');
                        tabContainer.scrollTo({
                            top: 0,
                            behavior: 'smooth'
                        });
                    }}
                >
                    <span className={`w-full h-full rounded-xl font-bold text-sm sm:text-xl p-2 sm:p-4 ${activeTab === key ? '' : 'bg-black/30 hover:bg-black/50'}`}>
                        {key.charAt(0).toUpperCase() + key.slice(1)}
                    </span>
                </button>
            ))}
        </div>
    )

    return (
        <Section title='My Experience' head={header} {...props}>

            <div className='flex flex-row mt-4  w-full items-start justify-center sm:p-4 '>
                <div className='grid grid-cols-1 lg:grid-cols-2 overflow-hidden transition-all duration-200'
                    id='professional'
                    style={{
                        maxWidth: activeTab === 'professional' ? '100%' : '0',
                        maxHeight: activeTab === 'professional' ? '100%' : '0',
                        opacity: activeTab === 'professional' ? '1' : '0'
                    }}>
                    {data.professional.map((item, i) => (
                        <ProfessionalItem key={i} data={item} />
                    ))}
                </div>

                <div className='gap-4 grid grid-cols-1 lg:grid-cols-2 overflow-hidden transition-all duration-200'
                    id='education'
                    style={{
                        maxWidth: activeTab === 'education' ? '100%' : '0',
                        maxHeight: activeTab === 'education' ? '100%' : '0',
                        opacity: activeTab === 'education' ? '1' : '0'
                    }}>
                    {data.education.map((item, i) => (
                        <EducationItem key={i} data={item} />
                    ))}
                </div>
                <div className='gap-4 grid grid-cols-1 lg:grid-cols-2 overflow-hidden transition-all duration-200'
                    id='projects'
                    style={{
                        maxWidth: activeTab === 'projects' ? '100%' : '0',
                        maxHeight: activeTab === 'projects' ? '100%' : '0',
                        opacity: activeTab === 'projects' ? '1' : '0'
                    }}>
                    {
                        data.projects.map((item, i) => (
                            <ProjectItem key={i} data={item} />
                        ))
                    }
                </div>
            </div>
        </Section>
    );
}

export default ExperienceSection;
