import React, { useEffect, useState } from 'react';
import { FaArrowUp } from "react-icons/fa";
import Header from '@/components/Header';
import Footer from '@/components/Footer';

export default function Layout({ children }) {
    const [pageSize, setPageSize] = useState({
        width: "100%",
        height: "100%",
    });
    const [contentY, setContentY] = useState(0);

    useEffect(() => {
        const handleResize = () => {
            const main = document.querySelector('main');
            const width = main.clientWidth;
            const height = main.clientHeight;
            setPageSize(prevSize => ({
                ...prevSize,
                width,
                height,
            }));
        };

        const handleScroll = () => {
            setContentY(document.querySelector('main').scrollTop);
        };
        const main = document.querySelector('main');
        window.addEventListener('resize', handleResize);
        main.addEventListener('resize', handleResize);
        main.addEventListener('scroll', handleScroll);
        handleResize();
        handleScroll();

        return () => {
            window.removeEventListener('resize', handleResize);
            main.removeEventListener('resize', handleResize);
            main.removeEventListener('scroll', handleScroll);

        };
    }, []);

    return (
        <>
            <div className="fixed top-0 left-0 w-screen h-screen flex flex-col bg-background dark:bg-background-dark text-text dark:text-text-dark">
                <Header className='w-full h-10 p-4 absolute top-0'
                    style={{
                        height: `${Math.max(pageSize?.height / 20, pageSize?.height / 12 - contentY)}px`,
                        backgroundColor: `rgba(0, 0, 0, ${Math.min(0.2, contentY / (pageSize?.height / 12))})`,
                    }} />

                <main className='w-full h-full flex justify-center overflow-y-auto overflow-x-hidden relative'>
                    <div className='w-[100vw] lg:w-3/4 px-2 items-center justify-center gap-4'>
                        {children}
                    </div>
                </main>

                <Footer className='w-full h-10 p-4 fixed bottom-0 hidden lg:flex' />
            </div >

            <button
                onClick={() => {
                    document.querySelector('main').scrollTo({ top: 0, behavior: 'smooth' });
                }}
                className="fixed w-[6vh] h-[6vh] z-50 text-[3vh] flex items-center justify-center bottom-[10vh] sm:bottom-4 right-4 p-2 bg-accent dark:bg-accent-dark text-background dark:text-background-dark rounded-full opacity-50 hover:opacity-100 transition-opacity"
                style={{
                    opacity: contentY > pageSize?.height / 2 ? 1 : 0,
                }}
            >
                <FaArrowUp />
            </button>
            
        </>
    )
}