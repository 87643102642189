import * as FaIcon from "react-icons/fa";
import * as SiIcon from "react-icons/si";
import * as IoIcon from "react-icons/io";
import * as MdIcon from "react-icons/md";
import * as BsIcon from "react-icons/bs";
import * as TbIcon from "react-icons/tb";
import * as LuIcon from "react-icons/lu"
import * as AiIcon from "react-icons/ai"
import * as GiIcon from "react-icons/gi"
import * as VscIcon from "react-icons/vsc"


const Icons = {
    'fa': FaIcon,
    'si': SiIcon,
    'io': IoIcon,
    'md': MdIcon,
    'bs': BsIcon,
    'tb': TbIcon,
    'lu': LuIcon,
    'ai': AiIcon,
    'gi': GiIcon,
    "vs": VscIcon
};

function getIcon(iconName){
    const libName = iconName.slice(0, 2).toLowerCase();
    const lib = libName in Icons ? Icons[libName] : {};
    return iconName in lib ? lib[iconName] : null;
}

export {Icons, getIcon}