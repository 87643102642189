import Section from "./Section";
import { getIcon } from "@/components/Icon";
import ReactMarkdown from 'react-markdown';

export default function AboutSection({ data, ...props }) {
    return (
        <Section title="About Me" {...props}>
            <div className="flex flex-wrap gap-0">
                {data?.softskills && (
                    <div className="flex w-full order-1 xl:order-0 items-center justify-center">
                        <div
                            className="flex flex-row justify-start gap-4 overflow-x-auto cursor-grab active:cursor-grabbing rounded-3xl p-2"
                            style={{ scrollbarWidth: "none", scrollbarHeight: "none" }}
                            onMouseDown={(e) => {
                                const element = e.currentTarget;
                                element.isDragging = true;
                                element.startX = e.pageX - element.offsetLeft; // Position initiale de la souris
                                element.scrollLeftStart = element.scrollLeft; // Position initiale du scroll
                            }}
                            onMouseMove={(e) => {
                                const element = e.currentTarget;
                                if (!element.isDragging) return; // Ne rien faire si on ne "grab" pas
                                e.preventDefault(); // Bloque la sélection de texte
                                const x = e.pageX - element.offsetLeft; // Position actuelle de la souris
                                const walk = (x - element.startX) * 1.5; // Multiplieur pour ajuster la vitesse
                                element.scrollLeft = element.scrollLeftStart - walk; // Déplacement du scroll
                            }}
                            onMouseUp={(e) => {
                                e.currentTarget.isDragging = false; // Arrête le "grab"
                            }}
                            onMouseLeave={(e) => {
                                e.currentTarget.isDragging = false; // Arrête le "grab" si la souris quitte la div
                            }}
                        >
                            {data?.softskills?.map((item, index) => {
                                const Icon = getIcon(item.icon);
                                if (!Icon) return null;
                                return (
                                    <div
                                        key={index}
                                        className="flex flex-col relative min-w-[200px] p-4 justify-center items-center bg-gradient-to-r from-accent to-accent-dark dark:bg-gradient-to-r dark:from-black/30 dark:to-black/10 rounded-3xl shadow-sm"
                                    >
                                        <Icon className="text-[50px] text-text-dark dark:text-accent" />
                                        <span className="text-white h-2/3 text-center font-bold text-sm sm:text-lg flex items-center select-none">
                                            {item.name}
                                        </span>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                )}

                {/* Image */}
                <div className="flex flex-1 w-full xl:w-1/2 items-start justify-center p-4 order-0 xl:order-1">
                    <img src="https://placehold.co/500x600" alt="placeholder" className="rounded-3xl" />
                </div>

                {/* Description */}
                <div className="flex flex-col flex-1 w-full xl:w-1/2 p-4 gap-4 order-3">
                    <div className="flex flex-col w-full h-full items-start justify-start gap-4">
                        {data?.description?.split('\n').map((paragraph, index) => (
                            <ReactMarkdown key={index} className="text-black dark:text-white text-sm md:text-lg">
                                {paragraph}
                            </ReactMarkdown>
                        ))}
                    </div>
                </div>
            </div>
        </Section>
    );
}
