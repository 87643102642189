import { motion } from "framer-motion";

const Photo = ({ path, ...props }) => {
    const properties = {
        ...props,
        className: "relative " + props.className
    }
    return (
        <div {...properties}>
            <div className="w-full h-fullflex items-center justify-center mx-auto my-auto">
                <img
                    src={path}
                    priority="true"
                    quality={100}
                    alt=""
                    className="w-full h-full object-cover"
                />
                {/* Circle */}
                <motion.svg
                    className='h-full w-full mix-blend-lighten z-index-1 absolute top-0 left-0'
                    fill="transparent"
                    viewBox="0 0 506 506"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <motion.circle
                        className="stroke-current text-accent"
                        cx="253"
                        cy="253"
                        r="250"
                        strokeWidth="4"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        initial={{ strokeDasharray: "24 10 0 0" }}
                        animate={{
                            strokeDasharray: ["15 130 25 25", "16 25 92 72", "4 250 22 22"],
                            rotate: [120, 360]
                        }}
                        transition={{
                            duration: 20,
                            repeat: Infinity,
                            repeatType: "reverse"
                        }}
                    />
                </motion.svg>
            </div>
        </div>
    );
}

export default Photo;